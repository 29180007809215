import React, { useContext, useCallback, useReducer, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { RotatingLines } from  'react-loader-spinner'

export default () => {

  window.addEventListener('DOMContentLoaded', () => {

    /**
     * カテゴリー一覧を配置する要素
     */
    const categoryHeaderElement = document.getElementById('headerCategory');

    if (categoryHeaderElement) {

      /**
       * デフォルトの親カテゴリーID。data-category属性から取得。
       * data-category属性がなければ 0 を指定
       */
      const defaultParentId: number = categoryHeaderElement.dataset.category ? Number(categoryHeaderElement.dataset.category) : 0;

      type Category = {
        id: number;
        url: string;
        name: string;
        level: number;
        hasChildren: boolean;
        index?: number;
        type?: 'back' | 'enter';
      }

      type CategoryData = {
        ancestors: {
          id: number;
          url: string;
          name: string;
        }[];
        children: Category[]
      }

      /**
       * ステータスの初期設定
       */
       type InitialState = {
        ancestors: Category[];
        categories: Category[];
        isInitialized: boolean;
        isLoading: boolean;
      }
      const initialState: InitialState = {
        ancestors: [],
        categories: [],
        isInitialized: false,
        isLoading: false
      }

      type Action = {
        type: string;
        payload?: any;
      }

      /**
       * ステータスのreducer
       */
      const reducer = (state: any, action: Action) => {
        switch (action.type) {
          case 'INITIALIZED':
            return {
              ...state,
              isInitialized: true
            }
          case 'LOADING_START':
            return {
              ...state,
              isLoading: true
            }
          case 'LOADING_END':
            return {
              ...state,
              isLoading: false
            }
          case 'CATEGORIES_UPDATE':
            return {
              ...state,
              categories: action.payload
            }
          case 'ANCESTORS_UPDATE':
            return {
              ...state,
              ancestors: action.payload
            }
          default:
            return state;
        }
      }

      const CategoriesContext = React.createContext<[InitialState, React.Dispatch<Action>]>([initialState, null]);

      /**
       * 子カテゴリーの一覧データを非同期で取得
       */
      const getCategoryChildren = (parentId: number, type: 'back' | 'enter', onLoad: (data: CategoryData) => void ) => {
        fetch(`/get_category_children?parent=${ parentId }`)
          .then(response => response.json())
          .then(response => {
            const data = response as CategoryData;
            data.children.forEach((child, index) => {
              child.index = index;
              child.type = type;
            });
            onLoad(data)
          })
          .catch(error => console.error(error));
      }

      /**
       * カテゴリー一覧のナビ
       */
      const CategoryNavi = () => {
        const [state, dispatch] = useContext(CategoriesContext);

        const onClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
          dispatch({
            type: 'LOADING_START'
          });
          getCategoryChildren(Number(e.currentTarget.dataset.parent), 'back', data => {
            dispatch({
              type: 'ANCESTORS_UPDATE',
              payload: data.ancestors
            });
            dispatch({
              type: 'CATEGORIES_UPDATE',
              payload: data.children
            });
            dispatch({
              type: 'LOADING_END'
            });
          });
        }, [getCategoryChildren]);

        return (
          <ul className="headerCategory__navi">
            <li className="headerCategory__naviItem">
              <button className="headerCategory__naviButton" type="button" data-parent="0" onClick={(e) => onClick(e)}>全部</button>
            </li>
            { state.ancestors?.length ? state.ancestors.map((ancestor, index) => (
              <li className="headerCategory__naviItem" key={index}>
                { state.ancestors?.length > index + 1 ? <button className="headerCategory__naviButton" type="button" data-parent={ancestor.id} onClick={(e) => onClick(e)}>{ancestor.name}</button>
                :
                <a className="headerCategory__naviButton" href={ancestor.url}>{ancestor.name}</a> }
              </li>
            )) : '' }
            <li style={{marginLeft: '0.5em'}}>
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="12"
                visible={state.isLoading}
              />
            </li>
          </ul>
        )
      }

      /**
       * カテゴリー一覧
       */
      const CategoryList = () => {
        const [state, dispatch] = useContext(CategoriesContext);
        const nodeRefs = useRef([]);
        state.categories.forEach((_, i) => {
          nodeRefs.current[i] = React.createRef();
        });

        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          const value = Number(e.currentTarget.value);
          dispatch({
            type: 'LOADING_START'
          });
          getCategoryChildren(state.categories[value].id, 'enter', data => {
            dispatch({
              type: 'ANCESTORS_UPDATE',
              payload: data.ancestors
            });
            dispatch({
              type: 'CATEGORIES_UPDATE',
              payload: data.children
            });
            dispatch({
              type: 'LOADING_END'
            });
          });
        };

        return (
          <ul className="headerCategory__list" style={{ height: 45 * state.categories?.length + 'px' }}>
            <TransitionGroup component={null}>
              { state.categories.length ? state.categories.map((category, index) => (
                <CSSTransition
                  key={category.id}
                  timeout={300}
                  classNames="state-"
                  nodeRef={nodeRefs.current[index]}
                >
                  <li className="headerCategory__listItem" key={index} data-index={category.index} data-level={category.level} data-type={category.type} ref={nodeRefs.current[index]}>
                    { category.hasChildren ?
                      <button className="headerCategory__listButton" type="button" value={index} onClick={onClick}>{category.name}</button>
                      :
                      <a className="headerCategory__listButton" href={category.url}>{category.name}</a>
                    }
                  </li>
                </CSSTransition>
              )) : '' }
            </TransitionGroup>
          </ul>
        )
      }

      /**
       * カテゴリー一覧全体
       */
      const App = () => {
        const [state, dispatch] = useReducer(reducer, initialState);

        useEffect(() => {
          if (!state.isInitialized) {
            getCategoryChildren(defaultParentId, 'enter', data => {
              dispatch({
                type: 'ANCESTORS_UPDATE',
                payload: data.ancestors
              });
              dispatch({
                type: 'CATEGORIES_UPDATE',
                payload: data.children
              })
            });
            dispatch({
              type: 'INITIALIZED'
            });
          }
        }, []);

        return (
          <CategoriesContext.Provider value={[state, dispatch]}>
            <CategoryNavi />
            <CategoryList />
          </CategoriesContext.Provider>
        );
      }

      /**
       * Reactのレンダリング
       */
      const root = createRoot(categoryHeaderElement);
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }
  
  });

}