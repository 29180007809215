"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _prop = _interopRequireDefault(require("./prop"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var parseFunction = function parseFunction(props, test) {
  return Boolean(test(props));
};

var parseObject = function parseObject(props, test) {
  var keys = Object.keys(test);
  var length = keys.length;

  for (var index = 0; index < length; index += 1) {
    var key = keys[index];
    var expected = test[key];
    var value = (0, _prop.default)(key)(props);

    if (expected !== value) {
      return false;
    }
  }

  return true;
};

var parseString = function parseString(props, test) {
  return Boolean((0, _prop.default)(test)(props));
};

var parseMap = {
  function: parseFunction,
  object: parseObject,
  string: parseString
};
/**
 * Returns `pass` if prop is truthy. Otherwise returns `fail`
 * @example
 * import styled from "styled-components";
 * import { ifProp, palette } from "styled-tools";
 *
 * const Button = styled.button`
 *   background-color: ${ifProp("transparent", "transparent", palette(0))};
 *   color: ${ifProp(["transparent", "accent"], palette("secondary"))};
 *   font-size: ${ifProp({ size: "large" }, "20px", ifProp({ size: "medium" }, "16px", "12px"))};
 * `;
 */

var ifProp = function ifProp(test) {
  var pass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var fail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var result = true;

    if (Array.isArray(test)) {
      var length = test.length;
      var index = 0;

      while (result && index < length) {
        result = parseMap[_typeof(test[index])](props, test[index]);
        index += 1;
      }
    } else {
      result = parseMap[_typeof(test)](props, test);
    }

    var value = result ? pass : fail;
    return typeof value === "function" ? value(props) : value;
  };
};

var _default = ifProp;
exports.default = _default;