import $ from 'jquery';
import 'slick-carousel';

export default () => {

  $(() => {
    
    $('.heroSlider').slick({
      dots: true,
      arrows: true,
      centerMode: true,
      centerPadding: '25%',
      adaptiveHeight: true,
      //autoplay: true,
      autoplaySpeed: 5000,
      pauseOnFocus: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '0'
          }
        }
      ]
    });
    
    $('.banners--slider.banners--3columns').slick({
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

    $('.banners--slider.banners--5columns').slick({
      dots: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

    // ranking sp slider
    if (window.matchMedia('(max-width:768px)').matches) {
      $('.add-slider').slick({
        variableWidth: false,
        infinite: false,
        dots: true,
        slidesToShow: 1.25,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '',
      });
    }

    //product page
    $('.productImages__main').slick({
      dots: true,
      arrows: true,
    });
    $('.productImages__thumbnails__item').on('click', function () {
      const index = $(this).attr('data-index');
      $('.productImages__main').slick('slickGoTo', index);
      setThumbnailsCurrentState($(this), index);
    });
    $(document).on('click', '.l-cartinSku__figure', function () {
      const imgSrc = $(this).find('img').attr('src');
      const index = $(`.productImages__main__inner > img[src="${ imgSrc }"]`).attr('data-index');
      $('.productImages__main').slick('slickGoTo', index);
      setThumbnailsCurrentState($(this), index);
    });

    function setThumbnailsCurrentState ($clickedElement: JQuery<HTMLElement>, index: string) {
      const $productImages__thumbnails__item = $clickedElement.closest('.productMainSection').find('.productImages__thumbnails__item');
      $productImages__thumbnails__item.removeClass('is-current');
      $productImages__thumbnails__item.filter(`[data-index="${ index }"]`).addClass('is-current');
    }

    //about page
    $('.coBrandSlider').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            centerMode: true,
          }
        }
      ]
    });

  });

}